// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";

@import "fonts";

@import "screen";

@import "custom";
