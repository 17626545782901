body {
  font-family: "Kanit", sans-serif;
  font-size: 0.825rem;
}
.c-main {
  padding-top: 0.5rem;

  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
.dropdown-menu {
  padding: unset;
}
.logo-img {
  max-height: 45px;
  max-width: 45px;
}
.btn-row-sm {
  padding: 0.15em 0.4em;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
  margin-left: 2px;
  margin-right: 2px;
}
.btn-primary {
  background-color: #0076fe;
  border-color: #0076fe;
  color: #ffffff;
}
.btn-success {
  background-color: #66bf0a;
  border-color: #66bf0a;
  color: #ffffff;
}
.btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #ffffff;
}
.btn-danger {
  background-color: #fe1602;
  border-color: #fe1602;
  color: #ffffff;
}
.color-primary {
  color: #0076fe;
}
.color-success {
  color: #66bf0a;
}
.color-warning {
  color: #ffc107;
}
.color-danger {
  color: #fe1602;
}
.icon-button {
  background: none;
  border: none;
  padding: 0px 4px;
}
.pos-payment-method {
  border-radius: 4px;
  display: inline-block;
  margin: 0.5rem;
  padding: 0.5rem;
  text-align: center;
  width: 6rem;
}
.pos-payment-method:hover {
  cursor: pointer;
  background-color: #ebebeb;
  transition: 0.3s;
}
.pos-payment-method.active {
  background-color: #000000 !important;
  color: #ffffff;
}
.table th,
.table td {
  padding: 0.5rem;
}
.receipt-list.is-focus {
  background-color: #5f6f7e !important;
  color: #ffffff !important;
}
.shortcut-product-item {
  background-color: #0076fe !important;
  color: #ffffff;
}
.shortcut-product-item:hover {
  z-index: 10;
  transition: transform 0.5s;
  transform: scale(1.05);
}
